<template>
  <div>
    <div class="top">
      <div class="top-con">
        <div class="left">
          <a>石家庄</a>
        </div>
        <div class="right">
          <div class="con1" v-if="this.name != ''">
            <a >欢迎{{ this.name }}</a>&nbsp;&nbsp;
            <a @click="tuichu">退出</a>
          </div>
          <div class="con2">
            <router-link to="/gerenzhongxin"
              ><a href="">个人中心</a></router-link
            >
          </div>
        </div>
      </div>
    </div>
    <div class="leftmenu-div">
      <div class="leftmenu-content">
        <div class="leftmenu-content-header">
          <a href="">个人中心</a>
        </div>
        <ul class="leftmenu-content-ul">
          <li>
            <router-link to="/"><a href="">首页</a></router-link>
          </li>
          <li><a href="">我的发布</a></li>
          <li><a href="">我的发布</a></li>
          <li><a href="">我的发布</a></li>
          <li><a href="">我的发布</a></li>
        </ul>
        <div class="leftmenu-content-header accountset-header">
          <a href=""><span>账户设置</span></a>
        </div>
        <ul class="leftmenu-content-ul">
          <li><a href="">我的发布</a></li>
          <li><a href="">我的发布</a></li>
          <li><a href="">我的发布</a></li>
          <li><a href="">我的发布</a></li>
          <li><a href="">我的发布</a></li>
        </ul>
      </div>
    </div>
    <div class="rightmenu-div">
      <div class="webpart_js_1">
        <div class="uc-leftside">
          <!-- <router-link to="/gerenzhongxin"
            ><img src="" alt="" class="uc-head-img"
          /></router-link> -->
          <div class="uc-user-info">
            <router-link to="/gerenzhongxin"
              ><a class="uc-uname">{{ this.name }}</a></router-link
            >
          </div>
        </div>
      </div>
      <div class="webpart_js_3">
        <div class="rightside-publish-content">
          <div class="my-item-div">
            <div class="my-item-guide">
              <div class="my-item-tab my-item-tab-selected" @click="biaoge=true,fukuanma=false">最近发布的消息</div>
              <div class="my-item-tab my-item-tab-selected" style="color: red" @click="biaoge=false,fukuanma=true">
                剩余发布的消息（{{ platnum }}）点击付款
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="hello">
      <el-table :data="tableData" style="width: 100%" v-show="biaoge">
        <el-table-column prop="date" label="条数" width="180" />
        <el-table-column prop="name" label="职位" width="180" />
        <el-table-column prop="address" label="薪资" />
        <el-table-column label="操作">
          <el-button>删除</el-button> 
          <el-button>修改</el-button>
        </el-table-column>
      </el-table>
      <div class="fukuanma" v-show="fukuanma">
          <img
            src="../img/fukuanma.jpg"
            alt=""
            style="width: 150px; height: 200px"
          />
          <br>
          <span style="color:red;font-size:20px;font-weight: 400;" >1000条消息</span> 
        </div>
    </div>

    <div class="bottom">
      <div>
        <a
          target="_blank"
          href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
          rel="nofollow"
          >冀ICP备20010751号</a
        ><span>|</span
        ><a
          target="_blank"
          href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
          rel="nofollow"
          >冀ICP备20010751号-2</a
        ><span>|</span
        ><a
          target="_blank"
          href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
          rel="nofollow"
          >津文执网文【2019】</a
        ><span>|</span
        ><a
          target="_blank"
          href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
          rel="nofollow"
          >河北坤洲网络科技有限公司</a
        ><span>|</span
        ><a
          target="_blank"
          href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
          >联系我们</a
        >
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";

export default {
  data() {
    return {
      fukuanma: false,
      platnum: "",
      img: "",
      name: "",
      biaoge:true,
      tableData: [
        {
          date: "1",
          name: "保洁",
          address: "2500元-3000元",
        },
      ],
    };
  },

  mounted() {
    this.name = Cookies.get("phone");
    this.platnum = Cookies.get("platnum");
  },
  methods: {
    tuichu() {
      Cookies.remove("phone");
      Cookies.remove("platnum");
      this.$router.push("./Denglu");
    },
  },
};
</script>
<style lang="scss" scoped>
.xl-con a {
  width: 100%;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #555;
  white-space: nowrap;
}

.xl-con a:first-child {
  color: #ff552e;
}

.con2 img {
  width: 9px;
  margin-left: 9px;
}

.con2:hover .xl-con {
  display: block;
}

.xl-con {
  position: absolute;
  top: -1px;
  border: 1px solid #ddd;
  padding: 0 10px;
  display: none;
  z-index: 99;
  background-color: #fff;
}

.con2 {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  margin-left: 10px;
  padding: 0 10px;
}

.con1 a {
  color: #555;
  margin: 0 2px;
}

.left {
  color: red;
  margin-left: 100px;
}

.right {
  display: flex;
  height: 100%;
  align-items: center;
}
a {
  text-decoration: none;
  cursor: pointer;
  outline-style: none;
}
.top {
  width: 100%;
  height: 35px;
  line-height: 35px;
  background: #fff;
  color: #555;
  border-bottom: 1px solid #ddd;
}

.top-con {
  width: 1190px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

/* 这个算通用样式，初始化 */
body {
  font-size: 12px;
  margin: 0;
  color: rgb(51, 51, 51);
  min-width: 1190px;
}
.leftmenu-div {
  float: left;
  width: 184px;
  min-height: 500px;
  padding-top: 8px;
  margin-left: 400px;
  margin-top: 22px;
  
}
div {
  margin: 0 auto;
}
.leftmenu-content {
  padding-top: 25px;
  background: #fafafa;
  color: #595757;
  padding-bottom: 50px;
}
.leftmenu-content-header {
  width: 110px;
  height: 33px;
  line-height: 33px;
  margin: 0 auto;
  font-size: 18px;
  font-weight: 700;
  clear: both;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 12px;
}
.leftmenu-content-header {
  cursor: pointer;
}
.leftmenu-content-ul {
  margin-top: 4px;
  margin-left: 0;
  cursor: default;
}
.leftmenu-content-ul li {
  cursor: pointer;
  line-height: 42px;
  padding-left: 44px;
  font-size: 16px;
}
ul,
li {
  list-style: none;
  padding-left: 0;
}
.leftmenu-div a:hover {
  color: #ff552e;
}
.rightmenu-div {
  float: right;
  width: 810px;
  margin-top: 32px;
  margin-right: 450px;
}
.uc-leftside {
  position: relative;
  width: 542px;
  _width: 512px;
  height: 105px;
  margin-left: 0;
  _margin-left: 0;
  margin-bottom: 10px;
  float: left;
  z-index: 2;
}
.uc-head-img {
  float: left;
  width: 86px;
  height: 86px;
  border-radius: 50%;
  margin-top: 14px;
}
.uc-user-info {
  position: relative;
  height: 36px;
  width: 380px;
  padding-left: 25px;
  float: left;
  line-height: 53px;
  font-size: 12px;
  color: #898989;
}
.uc-uname {
  font-size: 20px;
  font-weight: 700;
  margin-right: 15px;
}
.rightside-publish-content {
  width: 815px;
  float: left;
  font-family: Microsoft YaHei, SimHei, Arial, Helvetica, sans-serif;
}
.my-item-div {
  width: 780px;
}
.my-item-guide {
  width: 100%;
  border-bottom: 1px solid #dfdfdf;
  height: 33px;
  margin-bottom: 14px;
}
.my-item-tab-selected {
  color: #e96c1f;
  border-bottom: 2px solid #e96c1f;
}
.my-item-tab {
  float: left;
  height: 32px;
  font-size: 14px;
  line-height: 17px;
  margin-right: 17px;
  padding: 0 3px;
  cursor: pointer;
}
.leftmenu-div a,
.rightmenu-div a,
.con2 a {
  color: #595757;
}
.bottom {
  overflow: hidden;
  width: 100%;
  height: 150px;
  /* background-color: red; */
  /* border: 1px solid black; */
  margin: 0 auto;
  line-height: 16px;
  text-align: center;
  color: #999;
}
.bottom div {
  height: 50px;
  line-height: 50px;
  width: 100%;
  margin-top: 90px;
  border-top: 1px solid #999;
}
.bottom div span {
  margin: 0 10px;
  color: #eee;
}

.bottom div a {
  color: #999;
  font-size: 13px;
  /* margin-left: 20px; */
}
</style>
<style scoped>
.hello {
  width: 1200px;

  overflow-x: hidden;
}
#from {
  width: 1200px;
}
tr {
  height: 40px;
}
.button-1 {
  width: 40px;
  height: 25px;
  color: white;
  background: #009688;
  border: none;
}
.button-2 {
  width: 40px;
  height: 25px;
  color: white;
  background: #ff5722;
  border: none;
  margin-left: 8px;
}
.fukuanma{
  margin-top: 100px;
  margin-left: 200px;
}
</style>